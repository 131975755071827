export default {
  clientName                : undefined,
  reporterUserPoolId        : undefined,
  reporterUserPoolClientId  : undefined,
  reporterLanguage          : undefined,
  loggedIn                  : false,
  invalidCredentials        : undefined,
  loggingIn                 : false,
  exceededAttempts          : undefined,
  report                    : undefined,
  reportNumber              : undefined,
  askOrganisationCodeForWeb : undefined,
  subscribingForNotification: undefined,
  subscribedForNotification : undefined,
  verifyingEmail            : undefined,
  emailVerified             : undefined,
  region                    : undefined,
  clientAiSpeechToText      : undefined,
  translationPreferenceId   : undefined
}
